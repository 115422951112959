import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import Layout from "../../components/layout"
import SummaryBanner from "../../components/summaryBanner/"
import Tabs from "../../components/tabs/"
import PdfItem from '../../components/pdfItem/'
import SEO from "../../components/seo"
import ProviderJobs from '../../components/provider-profile/providerJobs'
import { navigate } from '@reach/router'
import withLocation from '../../HOC/withLocation.js'
import { getById } from '../../reducers/actions/user.js'
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from 'draft-js'

const Services = ({services}) => {
  return ( 
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      {services.map((service, index) => (
        <div key={index} style={{border: '1px solid black', padding: '1rem', marginRight: '1rem'}}>
          <h3>{service.name}</h3>
          <p>{service.description}</p>
        </div>
      ))}
    </div>
  )
}

const Reviews = ({reviews}) => {
  return ( 
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      {reviews.map((review, index) => (
        <div key={index} style={{border: '1px solid black', padding: '1rem', width: '100%', marginTop: '1rem'}}>
          <div style={{display: 'flex', alignItems: 'flex-end', margin: '0.5rem 0 1rem 0'}}>
            <h3 style={{margin: '0 1rem 0 0'}}>{review.reviewer.name}</h3>
            <span>{`${review.score.toFixed(1)}/5.0`}</span>
          </div>
          <p>{review.comment}</p>
        </div>
      ))}
    </div>
  )
}

const ProviderDetail = ({search}) => {

  const user  = useSelector(state => state.userReducer.getUserById)
  const dispatch = useDispatch()

  const id = search.id
  const [isFirstRender, setIsFirstRender] = useState(true)
 
  const [tabs, setTabs] = useState({
    Trabajos: true,
    Servicios: false,
    Reseñas: false,
  })

  const onChangeTab = (tab) => {
    setTabs(
      Object.keys(tabs).reduce((acc, current) => ({...acc, [current]: current === tab}), {})
    )
  }

  useEffect(() => {
    if(id && isFirstRender) {
      setIsFirstRender(false)
      getById(dispatch)(id)
    }
  }, [user])

  const data = user.response && {
    title: user.response.provider.name,
    text: user.response.provider.description,
    contact: {
      Teléfono: user.response.contact.phone,
      Email: user.response.contact.email
    },
    highlights: {
      'Trabajos realizados': user.response.provider.nProjects,
      'Calificación': user.response.score,
    }
  }

  return (
    <Layout>
      <SEO title="Projecto" />
      { data && <SummaryBanner {...data}/>}
      <div style={{margin: '2.5rem 0'}}>
        <Tabs tabs={tabs} onChangeTab={onChangeTab}/>
      </div>
      {tabs['Trabajos'] && user.response && 
        <ProviderJobs id={id} status='completed'/>
      }
      {tabs['Servicios'] && user.response &&
          <Services services={user.response.provider.services} />
      }
      {tabs['Reseñas'] && user.response && <Reviews reviews={user.response.reviews} />}
    </Layout>
  )
}

export default withLocation(ProviderDetail)
